
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { AxiosApi } from "@helper/axiosApi"
import initialStates from "./state"
import { data } from "jquery"

// ** create apps
export const createApps = (data) => async (dispatch) => {
    try {
        dispatch(
            setAppsState([
                { key:'addAppsData.loading', value:true },
                { key:'addAppsData.data', value:null },
                { key:'addAppsData.error', value:false }
            ])
        )
        const response = await AxiosApi.post('admin/app-used/create', data)
        toast.success(response.data.message)
        dispatch(
            setAppsState([
                { key:'addAppsData.loading', value:false },
                { key:'addAppsData.data', value:response.data.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setAppsState([
                { key:'addAppsData.loading', value:false },
                { key:'addAppsData.error', value:false }
            ])
        )
    }
}

// ** get all apps
export const getAllApps = (data) => async (dispatch) => {
    
    try {
        dispatch(
            setAppsState([
                { key:'allAppsData.loading', value:true },
                { key:'allAppsData.data', value:null },
                { key:'allAppsData.error', value:false }
            ])
        )

        const response = await AxiosApi.get('/admin/app-used/list', {
            params: data
        })
        dispatch(
            setAppsState([
                { key:'allAppsData.loading', value:false },
                { key:'allAppsData.data', value:response.data?.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setAppsState([
                { key:'allAppsData.loading', value:false },
                { key:'allAppsData.error', value:true }
            ])
        )
    }
}

// ** get all apps
export const getAllActiveApps = (data) => async (dispatch) => {
    
    try {
        dispatch(
            setAppsState([
                { key:'allActiveAppsData.loading', value:true },
                { key:'allActiveAppsData.data', value:null },
                { key:'allActiveAppsData.error', value:false }
            ])
        )

        const response = await AxiosApi.get('/admin/app-used/active-list', {
            params: data
        })
        
        dispatch(
            setAppsState([
                { key:'allActiveAppsData.loading', value:false },
                { key:'allActiveAppsData.data', value:response.data?.data }
            ])
        )
    } catch (error) {
        
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setAppsState([
                { key:'allActiveAppsData.loading', value:false },
                { key:'allActiveAppsData.error', value:true }
            ])
        )
    }
}

// ** get all apps
export const updateApps = (data) => async (dispatch) => {
    try {
        dispatch(
            setAppsState([
                { key: "updateAppsData.loading", value:true },
                { key: "updateAppsData.data", value:null },
                { key: "updateAppsData.error", value:false }
            ])
        )
        
        const response = await AxiosApi.put(`admin/app-used/update/${data.apps_id}`, data.apps_details)
        toast.success(response.data.message)
        
        dispatch(
            setAppsState([
                { key: "updateAppsData.loading", value:false },
                { key: "updateAppsData.data", value:response.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setAppsState([
                { key: "updateAppsData.loading", value:false },
                { key: "updateAppsData.error", value:true }
            ])
        )
    }
}

// ** get all apps
export const deleteApps = (apps_id) => async (dispatch) => {
    try {
        dispatch(
            setAppsState([
                { key:"appsDeleteData.loading", value:true },
                { key:"appsDeleteData.data", value:null },
                { key:"appsDeleteData.error", value:true }
            ])
        )
        const response = await AxiosApi.delete(`/admin/app-used/delete/${apps_id}`)
        dispatch(
            setAppsState([
                { key:"appsDeleteData.loading", value:false },
                { key:"appsDeleteData.data", value:response.data.data }
            ])
        )
        toast.success(response.data.message)
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setAppsState([
                { key:"appsDeleteData.loading", value:false },
                { key:"appsDeleteData.error", value:false }
            ])
        )
    }
}

//  ** Active Deactive Apps
export const activeDeactiveApps = (data) => async (dispatch) => {
    const { id, status } = data
    try {
        dispatch(
            setAppsState([
                { key:"activeDeactiveAppsData.loading", value:true },
                { key:"activeDeactiveAppsData.data", value:null },
                { key:"activeDeactiveAppsData.error", value:false }
            ])
        )
        const response = await AxiosApi.put(`/admin/app-used/manage-status/${id}`)
        toast.success(response?.data?.message)
        dispatch(
            setAppsState([
                { key:"activeDeactiveAppsData.loading", value:false },
                { key:"activeDeactiveAppsData.data", value:response.data?.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setAppsState([
                { key:"activeDeactiveAppsData.loading", value:false },
                { key:"activeDeactiveAppsData.error", value:true }
            ])
        )
        // return rejectWithValue(error.response.data)
    }
}

export const appsSlice = createSlice({
    name: 'apps',
    initialState: initialStates,
    reducers: {
        setAppsState: (state, { payload }) => {
            try {
                
                if (Array.isArray(payload)) {
                    for (const obj of payload) {
                        
                        _.set(state, obj.key, obj.value)
                    }
                } else {
                    _.set(state, payload.key, payload.value)
                }
            } catch (error) {
                
            }
        }
    }
})

export const { setAppsState } = appsSlice.actions
const { reducer } = appsSlice
export default reducer