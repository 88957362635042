import { AxiosApi } from "@helper/axiosApi";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import initialStates from "./state";

// ** delete tech
export const deletetech = (tech_id) => async (dispatch) => {
  try {
      dispatch(
          techState([
              { key:"deleteTechData.loading", value:true },
              { key:"deleteTechData.data", value:null },
              { key:"deleteTechData.error", value:true }
          ])
      )
      const response = await AxiosApi.delete(`/admin/tech/delete/${tech_id}`)
      dispatch(
          techState([
              { key:"deleteTechData.loading", value:false },
              { key:"deleteTechData.data", value:response.data.data }
          ])
      )
      toast.success(response.data.message)
  } catch (error) {
      if (!error) {
          throw error.response
      }
      toast.error(error?.response?.data?.message)
      dispatch(
          techState([
              { key:"deleteTechData.loading", value:false },
              { key:"deleteTechData.error", value:false }
          ])
      )
  }
}

// ** update tech
export const updateTech = (data) => async (dispatch) => {
  try {
      dispatch(
          techState([
              { key: "updateTechData.loading", value:true },
              { key: "updateTechData.data", value:null },
              { key: "updateTechData.error", value:false }
          ])
      )
      
      const response = await AxiosApi.put(`admin/tech/update/${data?.tech_id}`, data?.tech_details)
      toast.success(response.data.message)
      
      dispatch(
          techState([
              { key: "updateTechData.loading", value:false },
              { key: "updateTechData.data", value:response.data }
          ])
      )
  } catch (error) {
      if (!error) {
          throw error.response
      }
      toast.error(error?.response?.data?.message)
      dispatch(
          techState([
              { key: "updateTechData.loading", value:false },
              { key: "updateTechData.error", value:true }
          ])
      )
  }
}

// ** create tech
export const createTech = (data) => async (dispatch) => {
  try {
      dispatch(
          techState([
              { key:'addTechData.loading', value:true },
              { key:'addTechData.data', value:null },
              { key:'addTechData.error', value:false }
          ])
      )
      const response = await AxiosApi.post('admin/tech/create', data)
      toast.success(response.data.message)
      dispatch(
          techState([
              { key:'addTechData.loading', value:false },
              { key:'addTechData.data', value:response.data.data }
          ])
      )
  } catch (error) {
      if (!error) {
          throw error.response
      }
      toast.error(error?.response?.data?.message)
      dispatch(
          techState([
              { key:'addTechData.loading', value:false },
              { key:'addTechData.error', value:false }
          ])
      )
  }
}

// ** get all tech
export const getAllTech = (data) => async (dispatch) => {
    try {
      dispatch(
        techState([
          { key: "allTechData.loading", value: true },
          { key: "allTechData.data", value: null },
          { key: "allTechData.error", value: false }
        ])
      );
  
      const response = await AxiosApi.get("/admin/tech/list", {
        params: data
      });
  
      dispatch(
        techState([
          { key: "allTechData.loading", value: false },
          { key: "allTechData.data", value: response.data?.data }
        ])
      );
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error?.response?.data?.message);
      dispatch(
        techState([
          { key: "allTechData.loading", value: false },
          { key: "allTechData.error", value: true }
        ])
      );
    }
  };

export const techSlice = createSlice({
  name: "tech",
  initialState: initialStates,
  reducers: {
    techState: (state, { payload }) => {
      try {
        if (Array.isArray(payload)) {
          for (const obj of payload) {
            _.set(state, obj.key, obj.value);
          }
        } else {
          _.set(state, payload.key, payload.value);
        }
      } catch (error) {}
    }
  }
});

export const { techState } = techSlice.actions;
const { reducer } = techSlice;
export default reducer;
