export default {
    loading: false,
    error: false,
    getProfileData: {
        data: null
    },
    languageTranslateData: {
        data: null
    },
    updateProfileData: {
        loading: false,
        data: null,
        error: false
    }
}