export default {
  allPersonalityData: {
    loading: false,
    error: false,
    data: null
  },
  addPersonalityData: {
    loading: false,
    error: false,
    data: null
  },
  updatePersonalityData: {
    loading: false,
    error: false,
    data: null
  },
  deletePersonalityData: {
    loading: false,
    error: false,
    data: null
  }
};
