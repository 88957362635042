export default {
    addImagesData:{
        loading: false,
        error: false,
        data: null
    },
    imagesData: {
        loading: false,
        error: false,
        data: null
    },
    allImagesData: {
        loading: false,
        error: false,
        data: null
    },
    getImagesDetailData: {
        loading: false,
        error: false,
        data: null
    },
    updateImagesData: {
        loading: false,
        error: false,
        data: null
    },
    activeDeactiveImagesData: {
        loading: false,
        error: false,
        data: null
    },
    imagesDeleteData: {
        loading: false,
        error: false,
        data: null
    }
}