
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { AxiosApi } from "@helper/axiosApi"
import initialStates from "./state"
import { useHistory } from "react-router-dom"
import { setProfileComplateStatus, setAccessToken } from "../../helper/authFunctions"
import _ from "lodash"

// const history = useHistory()

// ** Login Post
export const signInWithGoogle = ({body, history}) => async (dispatch) => {
    try {
        dispatch(setAuthState(
            {key: 'loading', value:true}
        ))

        const response = await AxiosApi.post('auth/social-login', {...body})

        toast.success(response.data.message)
        if (response) {
            setAccessToken(response.data.data.tokens.access.token)
            AxiosApi.defaults.headers.common[
                'Authorization'
            ] = `Bearer ${response.data.data.tokens.access.token}`
        }
        dispatch(setAuthState([
            {key: 'loading', value:false},
            {key: 'loginData', value:{user:response.data.data.user}}
        ]))
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error.response?.data.message)
        dispatch(setAuthState([
            {key: 'loading', value: false},
            {key: 'loginData', value: null},
            {key: 'error', value: false}
        ]))
    }
}
// ** Login Post
export const loginAdmin = (data) => async (dispatch) => {
    try {
        dispatch(setAuthState(
            {key: 'loading', value:true}
        ))
        const response = await AxiosApi.post('admin/auth/login', {...data})
        toast.success(response.data.message)
        if (response) {
            setAccessToken(response.data.data.tokens.access.token)
            AxiosApi.defaults.headers.common[
                'Authorization'
            ] = `Bearer ${response.data.data.tokens.access.token}`
        }
        dispatch(setAuthState([
            {key: 'loading', value:false},
            {key: 'loginData', value:{user:response.data.data.user}}
        ]))
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error.response?.data.message)
        dispatch(setAuthState([
            {key: 'loading', value: false},
            {key: 'loginData', value: null},
            {key: 'error', value: false}
        ]))
    }
}

// ** send otp for forgot-password
export const sendOtp = (data) => async (dispatch) => {
    try {
        dispatch(setAuthState(
            {key: 'loading', value:true}
        ))
        const response = await AxiosApi.post('auth/send-otp', data)
        toast.success(response.data.message)
        toast.success(response.data.otp)
        dispatch(setAuthState([
            {key: 'loading', value:false},
            {key: 'error', value:false}
        ]))
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error.response.data.message)
        dispatch(setAuthState([
            {key: 'loading', value:false},
            {key: 'error', value:true}
        ]))
    }
}

// ** verify otp for forgot-password
export const verifyOtp = (data) => async (dispatch) => {
    try {
        dispatch(setAuthState(
            {key: 'loading', value:true}
        ))
        const response = await AxiosApi.post('auth/verify-otp', data)
        toast.success(response.data.message)
        dispatch(setAuthState([
            {key: 'loading', value:false},
            {key: 'error', value:false}
        ]))
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error.response.data.message)
        dispatch(setAuthState([
            {key: 'loading', value:false},
            {key: 'error', value:true}
        ]))
    }
}

// ** forgot-password
export const forgotPassword = (data) => async (dispatch) => {
    try {
        dispatch(setAuthState(
            {key: 'loading', value:true},
            {key: 'error', value:false}
        ))
        const response = await AxiosApi.post('auth/forgot-password', data)
        toast.success(response.data.message)
        dispatch(setAuthState([
            {key: 'loading', value:false},
            {key: 'error', value:false}
        ]))
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error.response.data.message)
        dispatch(setAuthState([
            {key: 'loading', value:false},
            {key: 'error', value:true}
        ]))
    }
}

// ** reset password
export const resetPassword = (data) => async (dispatch) => {
    try {
        dispatch(setAuthState([
            {key: 'resetPasswordData.loading', value:true},
            {key: 'resetPasswordData.error', value:false}
        ]))
        const response = await AxiosApi.post('/auth/reset-password', data)
        toast.success(response.data.message)
        dispatch(setAuthState([
            {key: 'resetPasswordData.loading', value:false},
            {key: 'resetPasswordData.data', value:response.data.data}
        ]))
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error.response.data.message)
        dispatch(setAuthState([
            {key: 'resetPasswordData.loading', value: false},
            {key: 'resetPasswordData.error', value: true}
        ]))
    }
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialStates,
    reducers: {
        setAuthState: (state, { payload }) => {
            if (Array.isArray(payload)) {
                for (const obj of payload) {
                    _.set(state, obj.key, obj.value)
                }
            } else {
                _.set(state, payload.key, payload.value)
            }
        }
    }
})

const { reducer } = authSlice
export const { setAuthState } = authSlice.actions
export default reducer