export default {
    addAppsData:{
        loading: false,
        error: false,
        data: null
    },
    appsData: {
        loading: false,
        error: false,
        data: null
    },
    allAppsData: {
        loading: false,
        error: false,
        data: null
    },
    allActiveAppsData: {
        loading: false,
        error: false,
        data: null
    },
    updateAppsData: {
        loading: false,
        error: false,
        data: null
    },
    activeDeactiveAppsData: {
        loading: false,
        error: false,
        data: null
    },
    appsDeleteData: {
        loading: false,
        error: false,
        data: null
    }
}