import { AxiosApi } from "@helper/axiosApi";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import initialStates from "./state";

// ** delete personality
export const deletePersonality = (personality_id) => async (dispatch) => {
  try {
      dispatch(
          setPersonalityState([
              { key:"deletePersonalityData.loading", value:true },
              { key:"deletePersonalityData.data", value:null },
              { key:"deletePersonalityData.error", value:true }
          ])
      )
      const response = await AxiosApi.delete(`/admin/personality/delete/${personality_id}`)
      dispatch(
          setPersonalityState([
              { key:"deletePersonalityData.loading", value:false },
              { key:"deletePersonalityData.data", value:response.data.data }
          ])
      )
      toast.success(response.data.message)
  } catch (error) {
      if (!error) {
          throw error.response
      }
      toast.error(error?.response?.data?.message)
      dispatch(
          setPersonalityState([
              { key:"deletePersonalityData.loading", value:false },
              { key:"deletePersonalityData.error", value:false }
          ])
      )
  }
}

// ** update language
export const updatePersonality = (data) => async (dispatch) => {
  try {
      dispatch(
          setPersonalityState([
              { key: "updatePersonalityData.loading", value:true },
              { key: "updatePersonalityData.data", value:null },
              { key: "updatePersonalityData.error", value:false }
          ])
      )
      
      const response = await AxiosApi.put(`admin/personality/update/${data?.personality_id}`, data?.personality_details)
      toast.success(response.data.message)
      
      dispatch(
          setPersonalityState([
              { key: "updatePersonalityData.loading", value:false },
              { key: "updatePersonalityData.data", value:response.data }
          ])
      )
  } catch (error) {
      if (!error) {
          throw error.response
      }
      toast.error(error?.response?.data?.message)
      dispatch(
          setPersonalityState([
              { key: "updatePersonalityData.loading", value:false },
              { key: "updatePersonalityData.error", value:true }
          ])
      )
  }
}

// ** create personality
export const createPersonality = (data) => async (dispatch) => {
  try {
      dispatch(
          setPersonalityState([
              { key:'addPersonalityData.loading', value:true },
              { key:'addPersonalityData.data', value:null },
              { key:'addPersonalityData.error', value:false }
          ])
      )
      const response = await AxiosApi.post('admin/personality/create', data)
      toast.success(response.data.message)
      dispatch(
          setPersonalityState([
              { key:'addPersonalityData.loading', value:false },
              { key:'addPersonalityData.data', value:response.data.data }
          ])
      )
  } catch (error) {
      if (!error) {
          throw error.response
      }
      toast.error(error?.response?.data?.message)
      dispatch(
          setPersonalityState([
              { key:'addPersonalityData.loading', value:false },
              { key:'addPersonalityData.error', value:false }
          ])
      )
  }
}

// ** get all personalities
export const getAllPersonalities = (data) => async (dispatch) => {
  try {
    dispatch(
      setPersonalityState([
        { key: "allPersonalityData.loading", value: true },
        { key: "allPersonalityData.data", value: null },
        { key: "allPersonalityData.error", value: false }
      ])
    );

    const response = await AxiosApi.get("/admin/personality/list", {
      params: data
    });

    dispatch(
      setPersonalityState([
        { key: "allPersonalityData.loading", value: false },
        { key: "allPersonalityData.data", value: response.data?.data }
      ])
    );
  } catch (error) {
    if (!error) {
      throw error.response;
    }
    toast.error(error?.response?.data?.message);
    dispatch(
      setPersonalityState([
        { key: "allPersonalityData.loading", value: false },
        { key: "allPersonalityData.error", value: true }
      ])
    );
  }
};

export const personalitySlice = createSlice({
  name: "personality",
  initialState: initialStates,
  reducers: {
    setPersonalityState: (state, { payload }) => {
      try {
        if (Array.isArray(payload)) {
          for (const obj of payload) {
            _.set(state, obj.key, obj.value);
          }
        } else {
          _.set(state, payload.key, payload.value);
        }
      } catch (error) {}
    }
  }
});

export const { setPersonalityState } = personalitySlice.actions;
const { reducer } = personalitySlice;
export default reducer;
