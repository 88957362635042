export default {
    loading: false,
    error: false,
    loginData: {},

    resetPasswordData:{
        loading: false,
        error: false,
        data: null
    }
}