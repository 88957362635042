
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { AxiosApi } from "@helper/axiosApi"
import initialStates from "./state"
import { data } from "jquery"

// ** create location
export const createLocation = (data) => async (dispatch) => {
    try {
        dispatch(
            setLocationState([
                { key:'addLocationData.loading', value:true },
                { key:'addLocationData.data', value:null },
                { key:'addLocationData.error', value:false }
            ])
        )
        const response = await AxiosApi.post('admin/location/create', data)
        toast.success(response.data.message)
        dispatch(
            setLocationState([
                { key:'addLocationData.loading', value:false },
                { key:'addLocationData.data', value:response.data.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setLocationState([
                { key:'addLocationData.loading', value:false },
                { key:'addLocationData.error', value:false }
            ])
        )
    }
}

// ** get all location
export const getAllLocation = (data) => async (dispatch) => {
    
    try {
        dispatch(
            setLocationState([
                { key:'allLocationData.loading', value:true },
                { key:'allLocationData.data', value:null },
                { key:'allLocationData.error', value:false }
            ])
        )

        const response = await AxiosApi.get('/admin/location/list', {
            params: data
        })
        
        dispatch(
            setLocationState([
                { key:'allLocationData.loading', value:false },
                { key:'allLocationData.data', value:response.data?.data }
            ])
        )
    } catch (error) {
        
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setLocationState([
                { key:'allLocationData.loading', value:false },
                { key:'allLocationData.error', value:true }
            ])
        )
    }
}

// ** get all location
export const getAllActiveLocation = (data) => async (dispatch) => {
    
    try {
        dispatch(
            setLocationState([
                { key:'allActiveLocationData.loading', value:true },
                { key:'allActiveLocationData.data', value:null },
                { key:'allActiveLocationData.error', value:false }
            ])
        )

        const response = await AxiosApi.get('/admin/location/active-list', {
            params: data
        })
        
        dispatch(
            setLocationState([
                { key:'allActiveLocationData.loading', value:false },
                { key:'allActiveLocationData.data', value:response.data?.data }
            ])
        )
    } catch (error) {
        
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setLocationState([
                { key:'allActiveLocationData.loading', value:false },
                { key:'allActiveLocationData.error', value:true }
            ])
        )
    }
}

// ** get all location
export const updateLocation = (data) => async (dispatch) => {
    try {
        dispatch(
            setLocationState([
                { key: "updateLocationData.loading", value:true },
                { key: "updateLocationData.data", value:null },
                { key: "updateLocationData.error", value:false }
            ])
        )
        
        const response = await AxiosApi.put(`admin/location/update/${data.location_id}`, data.location_details)
        toast.success(response.data.message)
        
        dispatch(
            setLocationState([
                { key: "updateLocationData.loading", value:false },
                { key: "updateLocationData.data", value:response.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setLocationState([
                { key: "updateLocationData.loading", value:false },
                { key: "updateLocationData.error", value:true }
            ])
        )
    }
}

// ** get all location
export const deleteLocation = (location_id) => async (dispatch) => {
    try {
        dispatch(
            setLocationState([
                { key:"locationDeleteData.loading", value:true },
                { key:"locationDeleteData.data", value:null },
                { key:"locationDeleteData.error", value:true }
            ])
        )
        const response = await AxiosApi.delete(`/admin/location/delete/${location_id}`)
        dispatch(
            setLocationState([
                { key:"locationDeleteData.loading", value:false },
                { key:"locationDeleteData.data", value:response.data.data }
            ])
        )
        toast.success(response.data.message)
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setLocationState([
                { key:"locationDeleteData.loading", value:false },
                { key:"locationDeleteData.error", value:false }
            ])
        )
    }
}

//  ** Active Deactive Location
export const activeDeactiveLocation = (data) => async (dispatch) => {
    const { id, status } = data
    try {
        dispatch(
            setLocationState([
                { key:"activeDeactiveLocationData.loading", value:true },
                { key:"activeDeactiveLocationData.data", value:null },
                { key:"activeDeactiveLocationData.error", value:false }
            ])
        )
        const response = await AxiosApi.put(`/admin/location/manage-status/${id}`)
        toast.success(response?.data?.message)
        dispatch(
            setLocationState([
                { key:"activeDeactiveLocationData.loading", value:false },
                { key:"activeDeactiveLocationData.data", value:response.data?.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setLocationState([
                { key:"activeDeactiveLocationData.loading", value:false },
                { key:"activeDeactiveLocationData.error", value:true }
            ])
        )
        // return rejectWithValue(error.response.data)
    }
}

export const locationSlice = createSlice({
    name: 'location',
    initialState: initialStates,
    reducers: {
        setLocationState: (state, { payload }) => {
            try {
                
                if (Array.isArray(payload)) {
                    for (const obj of payload) {
                        
                        _.set(state, obj.key, obj.value)
                    }
                } else {
                    _.set(state, payload.key, payload.value)
                }
            } catch (error) {
                
            }
        }
    }
})

export const { setLocationState } = locationSlice.actions
const { reducer } = locationSlice
export default reducer