
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { AxiosApi } from "@helper/axiosApi"
import initialStates from "./state"
import { data } from "jquery"

// ** create images
export const createImages = (data) => async (dispatch) => {
    try {
        dispatch(
            setImagesState([
                { key:'addImagesData.loading', value:true },
                { key:'addImagesData.data', value:null },
                { key:'addImagesData.error', value:false }
            ])
        )
        const response = await AxiosApi.post('users/image/add', data)
        toast.success(response.data.message)
        dispatch(
            setImagesState([
                { key:'addImagesData.loading', value:false },
                { key:'addImagesData.data', value:true }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setImagesState([
                { key:'addImagesData.loading', value:false },
                { key:'addImagesData.error', value:false }
            ])
        )
    }
}

// ** get images detail
export const getImagesDetail = (imageId) => async (dispatch) => {
    try {
        dispatch(
            setImagesState([
                { key:'getImagesDetailData.loading', value:true },
                { key:'getImagesDetailData.data', value:null },
                { key:'getImagesDetailData.error', value:false }
            ])
        )

        const response = await AxiosApi.get(`/admin/image/details/${imageId}`)
        dispatch(
            setImagesState([
                { key:'getImagesDetailData.loading', value:false },
                { key:'getImagesDetailData.data', value:response.data?.data  }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setImagesState([
                { key:'getImagesDetailData.loading', value:false },
                { key:'getImagesDetailData.error', value:true }
            ])
        )
    }
}

// ** get all images
export const getAllImages = (data) => async (dispatch) => {
    try {
        dispatch(
            setImagesState([
                { key:'allImagesData.loading', value:true },
                { key:'allImagesData.data', value:null },
                { key:'allImagesData.error', value:false }
            ])
        )

        const response = await AxiosApi.get('/admin/image/list', {
            params: data
        })
        dispatch(
            setImagesState([
                { key:'allImagesData.loading', value:false },
                { key:'allImagesData.data', value:response.data?.data  }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setImagesState([
                { key:'allImagesData.loading', value:false },
                { key:'allImagesData.error', value:true }
            ])
        )
    }
}

// ** get all images
export const updateImages = (data) => async (dispatch) => {
    try {
        dispatch(
            setImagesState([
                { key: "updateImagesData.loading", value:true },
                { key: "updateImagesData.data", value:null },
                { key: "updateImagesData.error", value:false }
            ])
        )

        const response = await AxiosApi.put(`admin/image/update/${data.images_id}`, data.images_details)
        toast.success(response.data.message)

        dispatch(
            setImagesState([
                { key: "updateImagesData.loading", value:false },
                { key: "updateImagesData.data", value:response.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setImagesState([
                { key: "updateImagesData.loading", value:false },
                { key: "updateImagesData.error", value:true }
            ])
        )
    }
}

// ** get all images
export const deleteImages = (images_id) => async (dispatch) => {
    try {
        dispatch(
            setImagesState([
                { key:"imagesDeleteData.loading", value:true },
                { key:"imagesDeleteData.data", value:null },
                { key:"imagesDeleteData.error", value:true }
            ])
        )
        const response = await AxiosApi.post(`/users/image/remove`, {
            images: images_id
        })
        dispatch(
            setImagesState([
                { key:"imagesDeleteData.loading", value:false },
                { key:"imagesDeleteData.data", value:response.data.data }
            ])
        )
        toast.success(response.data.message)
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setImagesState([
                { key:"imagesDeleteData.loading", value:false },
                { key:"imagesDeleteData.error", value:false }
            ])
        )
    }
}

//  ** Active Deactive Images
export const activeDeactiveImages = (data) => async (dispatch) => {
    const { id, status } = data
    try {
        dispatch(
            setImagesState([
                { key:"activeDeactiveImagesData.loading", value:true },
                { key:"activeDeactiveImagesData.data", value:null },
                { key:"activeDeactiveImagesData.error", value:false }
            ])
        )
        const response = await AxiosApi.put(`/admin/image/manage-status/${id}`)
        toast.success(response?.data?.message)
        dispatch(
            setImagesState([
                { key:"activeDeactiveImagesData.loading", value:false },
                { key:"activeDeactiveImagesData.data", value:response.data?.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setImagesState([
                { key:"activeDeactiveImagesData.loading", value:false },
                { key:"activeDeactiveImagesData.error", value:true }
            ])
        )
        // return rejectWithValue(error.response.data)
    }
}

export const imagesSlice = createSlice({
    name: 'images',
    initialState: initialStates,
    reducers: {
        setImagesState: (state, { payload }) => {
            try {
                if (Array.isArray(payload)) {
                    for (const obj of payload) {
                        _.set(state, obj.key, obj.value)
                    }
                } else {
                    _.set(state, payload.key, payload.value)
                }
            } catch (error) {
                
            }
        }
    }
})

export const { setImagesState } = imagesSlice.actions
const { reducer } = imagesSlice
export default reducer