export default {
    addCollectionData:{
        loading: false,
        error: false,
        data: null
    },
    collectionData: {
        loading: false,
        error: false,
        data: null
    },
    allCollectionData: {
        loading: false,
        error: false,
        data: null
    },
    updateCollectionData: {
        loading: false,
        error: false,
        data: null
    },
    activeDeactiveCollectionData: {
        loading: false,
        error: false,
        data: null
    },
    collectionDeleteData: {
        loading: false,
        error: false,
        data: null
    }
}