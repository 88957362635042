
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { AxiosApi } from "@helper/axiosApi"
import initialStates from "./state"
import { data } from "jquery"

// ** create profession
export const createProfession = (data) => async (dispatch) => {
    try {
        dispatch(
            setProfessionState([
                { key:'addProfessionData.loading', value:true },
                { key:'addProfessionData.data', value:null },
                { key:'addProfessionData.error', value:false }
            ])
        )
        const response = await AxiosApi.post('admin/profession/create', data)
        toast.success(response.data.message)
        dispatch(
            setProfessionState([
                { key:'addProfessionData.loading', value:false },
                { key:'addProfessionData.data', value:response.data.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setProfessionState([
                { key:'addProfessionData.loading', value:false },
                { key:'addProfessionData.error', value:false }
            ])
        )
    }
}

// ** get all profession
export const getAllProfession = (data) => async (dispatch) => {
    
    try {
        dispatch(
            setProfessionState([
                { key:'allProfessionData.loading', value:true },
                { key:'allProfessionData.data', value:null },
                { key:'allProfessionData.error', value:false }
            ])
        )

        const response = await AxiosApi.get('/admin/profession/list', {
            params: data
        })
        dispatch(
            setProfessionState([
                { key:'allProfessionData.loading', value:false },
                { key:'allProfessionData.data', value:response.data?.data }
            ])
        )
    } catch (error) {
        
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setProfessionState([
                { key:'allProfessionData.loading', value:false },
                { key:'allProfessionData.error', value:true }
            ])
        )
    }
}

// ** get all profession
export const getAllActiveProfession = (data) => async (dispatch) => {
    
    try {
        dispatch(
            setProfessionState([
                { key:'allActiveProfessionData.loading', value:true },
                { key:'allActiveProfessionData.data', value:null },
                { key:'allActiveProfessionData.error', value:false }
            ])
        )

        const response = await AxiosApi.get('/admin/profession/active-list', {
            params: data
        })
        dispatch(
            setProfessionState([
                { key:'allActiveProfessionData.loading', value:false },
                { key:'allActiveProfessionData.data', value:response.data?.data }
            ])
        )
    } catch (error) {
        
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setProfessionState([
                { key:'allActiveProfessionData.loading', value:false },
                { key:'allActiveProfessionData.error', value:true }
            ])
        )
    }
}

// ** get all Age
export const getAllActiveAge = (data) => async (dispatch) => {
    
    try {
        dispatch(
            setProfessionState([
                { key:'allActiveAgeData.loading', value:true },
                { key:'allActiveAgeData.data', value:null },
                { key:'allActiveAgeData.error', value:false }
            ])
        )

        const response = await AxiosApi.get('/admin/image/age-list')
        dispatch(
            setProfessionState([
                { key:'allActiveAgeData.loading', value:false },
                { key:'allActiveAgeData.data', value:response.data?.data }
            ])
        )
    } catch (error) {
        
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setProfessionState([
                { key:'allActiveAgeData.loading', value:false },
                { key:'allActiveAgeData.error', value:true }
            ])
        )
    }
}

// ** get all profession
export const updateProfession = (data) => async (dispatch) => {
    try {
        dispatch(
            setProfessionState([
                { key: "updateProfessionData.loading", value:true },
                { key: "updateProfessionData.data", value:null },
                { key: "updateProfessionData.error", value:false }
            ])
        )
        
        const response = await AxiosApi.put(`admin/profession/update/${data.profession_id}`, data.profession_details)
        toast.success(response.data.message)
        
        dispatch(
            setProfessionState([
                { key: "updateProfessionData.loading", value:false },
                { key: "updateProfessionData.data", value:response.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setProfessionState([
                { key: "updateProfessionData.loading", value:false },
                { key: "updateProfessionData.error", value:true }
            ])
        )
    }
}

// ** get all profession
export const deleteProfession = (profession_id) => async (dispatch) => {
    try {
        dispatch(
            setProfessionState([
                { key:"professionDeleteData.loading", value:true },
                { key:"professionDeleteData.data", value:null },
                { key:"professionDeleteData.error", value:true }
            ])
        )
        const response = await AxiosApi.delete(`/admin/profession/delete/${profession_id}`)
        dispatch(
            setProfessionState([
                { key:"professionDeleteData.loading", value:false },
                { key:"professionDeleteData.data", value:response.data.data }
            ])
        )
        toast.success(response.data.message)
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setProfessionState([
                { key:"professionDeleteData.loading", value:false },
                { key:"professionDeleteData.error", value:false }
            ])
        )
    }
}

//  ** Active Deactive Profession
export const activeDeactiveProfession = (data) => async (dispatch) => {
    const { id, status } = data
    try {
        dispatch(
            setProfessionState([
                { key:"activeDeactiveProfessionData.loading", value:true },
                { key:"activeDeactiveProfessionData.data", value:null },
                { key:"activeDeactiveProfessionData.error", value:false }
            ])
        )
        const response = await AxiosApi.put(`/admin/profession/manage-status/${id}`)
        toast.success(response?.data?.message)
        dispatch(
            setProfessionState([
                { key:"activeDeactiveProfessionData.loading", value:false },
                { key:"activeDeactiveProfessionData.data", value:response.data?.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setProfessionState([
                { key:"activeDeactiveProfessionData.loading", value:false },
                { key:"activeDeactiveProfessionData.error", value:true }
            ])
        )
        // return rejectWithValue(error.response.data)
    }
}

export const professionSlice = createSlice({
    name: 'profession',
    initialState: initialStates,
    reducers: {
        setProfessionState: (state, { payload }) => {
            try {
                
                if (Array.isArray(payload)) {
                    for (const obj of payload) {
                        
                        _.set(state, obj.key, obj.value)
                    }
                } else {
                    _.set(state, payload.key, payload.value)
                }
            } catch (error) {
                
            }
        }
    }
})

export const { setProfessionState } = professionSlice.actions
const { reducer } = professionSlice
export default reducer