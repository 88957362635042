
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { AxiosApi } from "@helper/axiosApi"
import initialStates from "./state"
import { data } from "jquery"

// ** create collection
export const createCollection = (data) => async (dispatch) => {
    try {
        dispatch(
            setCollectionState([
                { key:'addCollectionData.loading', value:true },
                { key:'addCollectionData.data', value:null },
                { key:'addCollectionData.error', value:false }
            ])
        )
        const response = await AxiosApi.post('users/collection/create', data)
        toast.success(response.data.message)
        dispatch(
            setCollectionState([
                { key:'addCollectionData.loading', value:false },
                { key:'addCollectionData.data', value:response.data.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setCollectionState([
                { key:'addCollectionData.loading', value:false },
                { key:'addCollectionData.error', value:false }
            ])
        )
    }
}

// ** get all collection
export const getAllCollection = (data) => async (dispatch) => {
    try {
        dispatch(
            setCollectionState([
                { key:'allCollectionData.loading', value:true },
                { key:'allCollectionData.data', value:null },
                { key:'allCollectionData.error', value:false }
            ])
        )

        const response = await AxiosApi.get('users/collection/list', {
            params: data
        })
        dispatch(
            setCollectionState([
                { key:'allCollectionData.loading', value:false },
                { key:'allCollectionData.data', value:response.data?.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setCollectionState([
                { key:'allCollectionData.loading', value:false },
                { key:'allCollectionData.error', value:true }
            ])
        )
    }
}

// ** get all collection
export const updateCollection = (data) => async (dispatch) => {
    try {
        dispatch(
            setCollectionState([
                { key: "updateCollectionData.loading", value:true },
                { key: "updateCollectionData.data", value:null },
                { key: "updateCollectionData.error", value:false }
            ])
        )
        const response = await AxiosApi.put(`users/collection/update/${data.collection_id}`, data.collection_details)
        toast.success(response.data.message)
        dispatch(
            setCollectionState([
                { key: "updateCollectionData.loading", value:false },
                { key: "updateCollectionData.data", value:response.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setCollectionState([
                { key: "updateCollectionData.loading", value:false },
                { key: "updateCollectionData.error", value:true }
            ])
        )
    }
}

// ** get all collection
export const deleteCollection = (collection_id) => async (dispatch) => {
    try {
        dispatch(
            setCollectionState([
                { key:"collectionDeleteData.loading", value:true },
                { key:"collectionDeleteData.data", value:null },
                { key:"collectionDeleteData.error", value:true }
            ])
        )
        const response = await AxiosApi.delete(`users/collection/delete/${collection_id}`)
        dispatch(
            setCollectionState([
                { key:"collectionDeleteData.loading", value:false },
                { key:"collectionDeleteData.data", value:response.data.data }
            ])
        )
        toast.success(response.data.message)
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setCollectionState([
                { key:"collectionDeleteData.loading", value:false },
                { key:"collectionDeleteData.error", value:false }
            ])
        )
    }
}

//  ** Active Deactive Collection
export const activeDeactiveCollection = (data) => async (dispatch) => {
    const { id, status } = data
    try {
        dispatch(
            setCollectionState([
                { key:"activeDeactiveCollectionData.loading", value:true },
                { key:"activeDeactiveCollectionData.data", value:null },
                { key:"activeDeactiveCollectionData.error", value:false }
            ])
        )
        const response = await AxiosApi.put(`users/collection/manage-status/${id}`)
        toast.success(response?.data?.message)
        dispatch(
            setCollectionState([
                { key:"activeDeactiveCollectionData.loading", value:false },
                { key:"activeDeactiveCollectionData.data", value:response.data?.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setCollectionState([
                { key:"activeDeactiveCollectionData.loading", value:false },
                { key:"activeDeactiveCollectionData.error", value:true }
            ])
        )
        // return rejectWithValue(error.response.data)
    }
}

export const collectionSlice = createSlice({
    name: 'collection',
    initialState: initialStates,
    reducers: {
        setCollectionState: (state, { payload }) => {
            try {
                if (Array.isArray(payload)) {
                    for (const obj of payload) {
                        _.set(state, obj.key, obj.value)
                    }
                } else {
                    _.set(state, payload.key, payload.value)
                }
            } catch (error) {
            }
        }
    }
})

export const { setCollectionState } = collectionSlice.actions
const { reducer } = collectionSlice
export default reducer