
import { AxiosApi } from "@helper/axiosApi"
import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import initialStates from "./state"

// ** create language
export const createLanguage = (data) => async (dispatch) => {
    try {
        dispatch(
            setLanguageState([
                { key:'addLanguageData.loading', value:true },
                { key:'addLanguageData.data', value:null },
                { key:'addLanguageData.error', value:false }
            ])
        )
        const response = await AxiosApi.post('admin/language/create', data)
        toast.success(response.data.message)
        dispatch(
            setLanguageState([
                { key:'addLanguageData.loading', value:false },
                { key:'addLanguageData.data', value:response.data.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setLanguageState([
                { key:'addLanguageData.loading', value:false },
                { key:'addLanguageData.error', value:false }
            ])
        )
    }
}

// ** get all language
export const getAllLanguage = (data) => async (dispatch) => {
    
    try {
        dispatch(
            setLanguageState([
                { key:'allLanguageData.loading', value:true },
                { key:'allLanguageData.data', value:null },
                { key:'allLanguageData.error', value:false }
            ])
        )

        const response = await AxiosApi.get('/admin/language/list', {
            params: data
        })
        
        dispatch(
            setLanguageState([
                { key:'allLanguageData.loading', value:false },
                { key:'allLanguageData.data', value:response.data?.data }
            ])
        )
    } catch (error) {
        
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setLanguageState([
                { key:'allLanguageData.loading', value:false },
                { key:'allLanguageData.error', value:true }
            ])
        )
    }
}

// ** get all language
export const getAllActiveLanguage = (data) => async (dispatch) => {
    
    try {
        dispatch(
            setLanguageState([
                { key:'allActiveLanguageData.loading', value:true },
                { key:'allActiveLanguageData.data', value:null },
                { key:'allActiveLanguageData.error', value:false }
            ])
        )

        const response = await AxiosApi.get('/admin/language/active-list', {
            params: data
        })
        
        dispatch(
            setLanguageState([
                { key:'allActiveLanguageData.loading', value:false },
                { key:'allActiveLanguageData.data', value:response.data?.data }
            ])
        )
    } catch (error) {
        
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setLanguageState([
                { key:'allActiveLanguageData.loading', value:false },
                { key:'allActiveLanguageData.error', value:true }
            ])
        )
    }
}

// ** get all language
export const updateLanguage = (data) => async (dispatch) => {
    try {
        dispatch(
            setLanguageState([
                { key: "updateLanguageData.loading", value:true },
                { key: "updateLanguageData.data", value:null },
                { key: "updateLanguageData.error", value:false }
            ])
        )
        
        const response = await AxiosApi.put(`admin/language/update/${data.language_id}`, data.language_details)
        toast.success(response.data.message)
        
        dispatch(
            setLanguageState([
                { key: "updateLanguageData.loading", value:false },
                { key: "updateLanguageData.data", value:response.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setLanguageState([
                { key: "updateLanguageData.loading", value:false },
                { key: "updateLanguageData.error", value:true }
            ])
        )
    }
}

// ** get all language
export const deleteLanguage = (language_id) => async (dispatch) => {
    try {
        dispatch(
            setLanguageState([
                { key:"languageDeleteData.loading", value:true },
                { key:"languageDeleteData.data", value:null },
                { key:"languageDeleteData.error", value:true }
            ])
        )
        const response = await AxiosApi.delete(`/admin/language/delete/${language_id}`)
        dispatch(
            setLanguageState([
                { key:"languageDeleteData.loading", value:false },
                { key:"languageDeleteData.data", value:response.data.data }
            ])
        )
        toast.success(response.data.message)
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setLanguageState([
                { key:"languageDeleteData.loading", value:false },
                { key:"languageDeleteData.error", value:false }
            ])
        )
    }
}

//  ** Active Deactive Language
export const activeDeactiveLanguage = (data) => async (dispatch) => {
    const { id, status } = data
    try {
        dispatch(
            setLanguageState([
                { key:"activeDeactiveLanguageData.loading", value:true },
                { key:"activeDeactiveLanguageData.data", value:null },
                { key:"activeDeactiveLanguageData.error", value:false }
            ])
        )
        const response = await AxiosApi.put(`/admin/language/manage-status/${id}`)
        toast.success(response?.data?.message)
        dispatch(
            setLanguageState([
                { key:"activeDeactiveLanguageData.loading", value:false },
                { key:"activeDeactiveLanguageData.data", value:response.data?.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setLanguageState([
                { key:"activeDeactiveLanguageData.loading", value:false },
                { key:"activeDeactiveLanguageData.error", value:true }
            ])
        )
        // return rejectWithValue(error.response.data)
    }
}

export const languageSlice = createSlice({
    name: 'language',
    initialState: initialStates,
    reducers: {
        setLanguageState: (state, { payload }) => {
            try {
                
                if (Array.isArray(payload)) {
                    for (const obj of payload) {
                        
                        _.set(state, obj.key, obj.value)
                    }
                } else {
                    _.set(state, payload.key, payload.value)
                }
            } catch (error) {
                
            }
        }
    }
})

export const { setLanguageState } = languageSlice.actions
const { reducer } = languageSlice
export default reducer