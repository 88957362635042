// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import profileSlice from './profile/slice'
import authSlice from './auth/slice'
import categorySlice from './category/slice'
import locationSlice from './location/slice'
import citySlice from './city/slice'
import professionSlice from './profession/slice'
import collectionSlice from './collection/slice'
import imagesSlice from './images/slice'
import languageSlice from './language/slice'
import internetUsageSlice from './internetUsage/slice'
import appsSlice from './apps/slice'
import mobilesSlice from './mobiles/slice'
import personalitySlice from './personality/slice'
import photographerSlice from './photographer/slice'
import techSlice from './tech/slice'

const rootReducer = {
  authSlice,
  categorySlice,
  locationSlice,
  citySlice,
  imagesSlice,
  professionSlice,
  collectionSlice,
  photographerSlice,
  profileSlice,
  appsSlice,
  mobilesSlice,
  internetUsageSlice,
  languageSlice,
  personalitySlice,
  techSlice,
  navbar,
  layout
}

export default rootReducer