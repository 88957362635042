export default {
    addPhotographerData:{
        loading: false,
        error: false,
        data: null
    },
    photographerData: {
        loading: false,
        error: false,
        data: null
    },
    allPhotographerData: {
        loading: false,
        error: false,
        data: null
    },
    allActivePhotographerData: {
        loading: false,
        error: false,
        data: null
    },
    allActiveAgeData: {
        loading: false,
        error: false,
        data: null
    },
    updatePhotographerData: {
        loading: false,
        error: false,
        data: null
    },
    activeDeactivePhotographerData: {
        loading: false,
        error: false,
        data: null
    },
    photographerDeleteData: {
        loading: false,
        error: false,
        data: null
    }
}