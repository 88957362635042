export default {
    addLanguageData:{
        loading: false,
        error: false,
        data: null
    },
    languageData: {
        loading: false,
        error: false,
        data: null
    },
    allLanguageData: {
        loading: false,
        error: false,
        data: null
    },
    allActiveLanguageData: {
        loading: false,
        error: false,
        data: null
    },
    updateLanguageData: {
        loading: false,
        error: false,
        data: null
    },
    activeDeactiveLanguageData: {
        loading: false,
        error: false,
        data: null
    },
    languageDeleteData: {
        loading: false,
        error: false,
        data: null
    }
}