export default {
    addInternetUsageData:{
        loading: false,
        error: false,
        data: null
    },
    internetUsageData: {
        loading: false,
        error: false,
        data: null
    },
    allInternetUsageData: {
        loading: false,
        error: false,
        data: null
    },
    allActiveInternetUsageData: {
        loading: false,
        error: false,
        data: null
    },
    updateInternetUsageData: {
        loading: false,
        error: false,
        data: null
    },
    activeDeactiveInternetUsageData: {
        loading: false,
        error: false,
        data: null
    },
    internetUsageDeleteData: {
        loading: false,
        error: false,
        data: null
    }
}