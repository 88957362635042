export default {
    addCategoryData:{
        loading: false,
        error: false,
        data: null
    },
    categoryData: {
        loading: false,
        error: false,
        data: null
    },
    allCategoryData: {
        loading: false,
        error: false,
        data: null
    },
    updateCategoryData: {
        loading: false,
        error: false,
        data: null
    },
    activeDeactiveCategoryData: {
        loading: false,
        error: false,
        data: null
    },
    categoryDeleteData: {
        loading: false,
        error: false,
        data: null
    }
}