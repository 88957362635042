export default {
    addMobilesData:{
        loading: false,
        error: false,
        data: null
    },
    mobilesData: {
        loading: false,
        error: false,
        data: null
    },
    allMobilesData: {
        loading: false,
        error: false,
        data: null
    },
    allActiveMobilesData: {
        loading: false,
        error: false,
        data: null
    },
    updateMobilesData: {
        loading: false,
        error: false,
        data: null
    },
    activeDeactiveMobilesData: {
        loading: false,
        error: false,
        data: null
    },
    mobilesDeleteData: {
        loading: false,
        error: false,
        data: null
    }
}