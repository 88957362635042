
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { AxiosApi } from "@helper/axiosApi"
import initialStates from "./state"
import { data } from "jquery"

// ** create category
export const createCategory = (data) => async (dispatch) => {
    try {
        dispatch(
            setCategoryState([
                { key:'addCategoryData.loading', value:true },
                { key:'addCategoryData.data', value:null },
                { key:'addCategoryData.error', value:false }
            ])
        )
        const response = await AxiosApi.post('admin/category/create', data)
        toast.success(response.data.message)
        dispatch(
            setCategoryState([
                { key:'addCategoryData.loading', value:false },
                { key:'addCategoryData.data', value:response.data.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setCategoryState([
                { key:'addCategoryData.loading', value:false },
                { key:'addCategoryData.error', value:false }
            ])
        )
    }
}

// ** get all category
export const getAllCategory = (data) => async (dispatch) => {
    try {
        dispatch(
            setCategoryState([
                { key:'allCategoryData.loading', value:true },
                { key:'allCategoryData.data', value:null },
                { key:'allCategoryData.error', value:false }
            ])
        )

        const response = await AxiosApi.get('/admin/category/list', {
            params: data
        })
        dispatch(
            setCategoryState([
                { key:'allCategoryData.loading', value:false },
                { key:'allCategoryData.data', value:response.data?.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setCategoryState([
                { key:'allCategoryData.loading', value:false },
                { key:'allCategoryData.error', value:true }
            ])
        )
    }
}

// ** get all category
export const updateCategory = (data) => async (dispatch) => {
    try {
        dispatch(
            setCategoryState([
                { key: "updateCategoryData.loading", value:true },
                { key: "updateCategoryData.data", value:null },
                { key: "updateCategoryData.error", value:false }
            ])
        )
        const response = await AxiosApi.put(`admin/category/update/${data.category_id}`, data.category_details)
        toast.success(response.data.message)
        dispatch(
            setCategoryState([
                { key: "updateCategoryData.loading", value:false },
                { key: "updateCategoryData.data", value:response.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setCategoryState([
                { key: "updateCategoryData.loading", value:false },
                { key: "updateCategoryData.error", value:true }
            ])
        )
    }
}

// ** get all category
export const deleteCategory = (category_id) => async (dispatch) => {
    try {
        dispatch(
            setCategoryState([
                { key:"categoryDeleteData.loading", value:true },
                { key:"categoryDeleteData.data", value:null },
                { key:"categoryDeleteData.error", value:true }
            ])
        )
        const response = await AxiosApi.delete(`/admin/category/delete/${category_id}`)
        dispatch(
            setCategoryState([
                { key:"categoryDeleteData.loading", value:false },
                { key:"categoryDeleteData.data", value:response.data.data }
            ])
        )
        toast.success(response.data.message)
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setCategoryState([
                { key:"categoryDeleteData.loading", value:false },
                { key:"categoryDeleteData.error", value:false }
            ])
        )
    }
}

//  ** Active Deactive Category
export const activeDeactiveCategory = (data) => async (dispatch) => {
    const { id, status } = data
    try {
        dispatch(
            setCategoryState([
                { key:"activeDeactiveCategoryData.loading", value:true },
                { key:"activeDeactiveCategoryData.data", value:null },
                { key:"activeDeactiveCategoryData.error", value:false }
            ])
        )
        const response = await AxiosApi.put(`/admin/category/manage-status/${id}`)
        toast.success(response?.data?.message)
        dispatch(
            setCategoryState([
                { key:"activeDeactiveCategoryData.loading", value:false },
                { key:"activeDeactiveCategoryData.data", value:response.data?.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setCategoryState([
                { key:"activeDeactiveCategoryData.loading", value:false },
                { key:"activeDeactiveCategoryData.error", value:true }
            ])
        )
        // return rejectWithValue(error.response.data)
    }
}

export const categorySlice = createSlice({
    name: 'category',
    initialState: initialStates,
    reducers: {
        setCategoryState: (state, { payload }) => {
            try {
                if (Array.isArray(payload)) {
                    for (const obj of payload) {
                        _.set(state, obj.key, obj.value)
                    }
                } else {
                    _.set(state, payload.key, payload.value)
                }
            } catch (error) {
            }
        }
    }
})

export const { setCategoryState } = categorySlice.actions
const { reducer } = categorySlice
export default reducer