
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { AxiosApi } from "@helper/axiosApi"
import initialStates from "./state"
import { data } from "jquery"

// ** create city
export const createCity = (data) => async (dispatch) => {
    try {
        dispatch(
            setCityState([
                { key:'addCityData.loading', value:true },
                { key:'addCityData.data', value:null },
                { key:'addCityData.error', value:false }
            ])
        )
        const response = await AxiosApi.post('admin/city/create', data)
        toast.success(response.data.message)
        dispatch(
            setCityState([
                { key:'addCityData.loading', value:false },
                { key:'addCityData.data', value:response.data.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setCityState([
                { key:'addCityData.loading', value:false },
                { key:'addCityData.error', value:false }
            ])
        )
    }
}

// ** get all city
export const getAllCity = (data) => async (dispatch) => {
    
    try {
        dispatch(
            setCityState([
                { key:'allCityData.loading', value:true },
                { key:'allCityData.data', value:null },
                { key:'allCityData.error', value:false }
            ])
        )

        const response = await AxiosApi.get('/admin/location/city-list', {
            params: data
        })
        
        dispatch(
            setCityState([
                { key:'allCityData.loading', value:false },
                { key:'allCityData.data', value:response.data?.data }
            ])
        )
    } catch (error) {
        
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setCityState([
                { key:'allCityData.loading', value:false },
                { key:'allCityData.error', value:true }
            ])
        )
    }
}

// ** get all city
export const getAllActiveCity = (data) => async (dispatch) => {
    
    try {
        dispatch(
            setCityState([
                { key:'allActiveCityData.loading', value:true },
                { key:'allActiveCityData.data', value:null },
                { key:'allActiveCityData.error', value:false }
            ])
        )

        const response = await AxiosApi.get('/admin/city/active-list', {
            params: data
        })
        
        dispatch(
            setCityState([
                { key:'allActiveCityData.loading', value:false },
                { key:'allActiveCityData.data', value:response.data?.data }
            ])
        )
    } catch (error) {
        
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setCityState([
                { key:'allActiveCityData.loading', value:false },
                { key:'allActiveCityData.error', value:true }
            ])
        )
    }
}

// ** get all city
export const updateCity = (data) => async (dispatch) => {
    try {
        dispatch(
            setCityState([
                { key: "updateCityData.loading", value:true },
                { key: "updateCityData.data", value:null },
                { key: "updateCityData.error", value:false }
            ])
        )
        
        const response = await AxiosApi.put(`admin/city/update/${data.city_id}`, data.city_details)
        toast.success(response.data.message)
        
        dispatch(
            setCityState([
                { key: "updateCityData.loading", value:false },
                { key: "updateCityData.data", value:response.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setCityState([
                { key: "updateCityData.loading", value:false },
                { key: "updateCityData.error", value:true }
            ])
        )
    }
}

// ** get all city
export const deleteCity = (city_id) => async (dispatch) => {
    try {
        dispatch(
            setCityState([
                { key:"cityDeleteData.loading", value:true },
                { key:"cityDeleteData.data", value:null },
                { key:"cityDeleteData.error", value:true }
            ])
        )
        const response = await AxiosApi.delete(`/admin/city/delete/${city_id}`)
        dispatch(
            setCityState([
                { key:"cityDeleteData.loading", value:false },
                { key:"cityDeleteData.data", value:response.data.data }
            ])
        )
        toast.success(response.data.message)
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setCityState([
                { key:"cityDeleteData.loading", value:false },
                { key:"cityDeleteData.error", value:false }
            ])
        )
    }
}

//  ** Active Deactive City
export const activeDeactiveCity = (data) => async (dispatch) => {
    const { id, status } = data
    try {
        dispatch(
            setCityState([
                { key:"activeDeactiveCityData.loading", value:true },
                { key:"activeDeactiveCityData.data", value:null },
                { key:"activeDeactiveCityData.error", value:false }
            ])
        )
        const response = await AxiosApi.put(`/admin/city/manage-status/${id}`)
        toast.success(response?.data?.message)
        dispatch(
            setCityState([
                { key:"activeDeactiveCityData.loading", value:false },
                { key:"activeDeactiveCityData.data", value:response.data?.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setCityState([
                { key:"activeDeactiveCityData.loading", value:false },
                { key:"activeDeactiveCityData.error", value:true }
            ])
        )
        // return rejectWithValue(error.response.data)
    }
}

export const citySlice = createSlice({
    name: 'city',
    initialState: initialStates,
    reducers: {
        setCityState: (state, { payload }) => {
            try {
                
                if (Array.isArray(payload)) {
                    for (const obj of payload) {
                        
                        _.set(state, obj.key, obj.value)
                    }
                } else {
                    _.set(state, payload.key, payload.value)
                }
            } catch (error) {
                
            }
        }
    }
})

export const { setCityState } = citySlice.actions
const { reducer } = citySlice
export default reducer