export default {
    addCityData:{
        loading: false,
        error: false,
        data: null
    },
    cityData: {
        loading: false,
        error: false,
        data: null
    },
    allCityData: {
        loading: false,
        error: false,
        data: null
    },
    allActiveCityData: {
        loading: false,
        error: false,
        data: null
    },
    updateCityData: {
        loading: false,
        error: false,
        data: null
    },
    activeDeactiveCityData: {
        loading: false,
        error: false,
        data: null
    },
    cityDeleteData: {
        loading: false,
        error: false,
        data: null
    }
}