import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AxiosApi } from "../../helper/axiosApi"
import { toast } from "react-toastify"
import initialStates from "./state"

// ** Profile Get Data
export const getProfileApi = (data = {}) => async (dispatch) => {
    try {
        dispatch(
            setProfileState([
                {key: 'loading', value:true},
                {key: 'error', value:false}
            ])
        )
        const response = await AxiosApi.post('auth/verify-token')
        ;
        dispatch(
            setProfileState([
                {key: 'loading', value: false},
                {key: 'getProfileData.data', value: response.data.data}
            ])
        )
    } catch (error) {
        ;
        if (!error) {
            throw error.response
        }
        toast.error(error.response?.data.message)
        dispatch(
            setProfileState([
                {key: 'loading', value: false},
                {key: 'error', value: true}
            ])
        )
    }
}

// ** update Profile
export const updateProfileApi = (data = {}) => async (dispatch) => {
    try {
        dispatch(
            setProfileState([
                {key: 'updateProfileData.loading', value:true},
                {key: 'updateProfileData.error', value:false}
            ])
        )
        const response = await AxiosApi.put(`auth/update-profile/${data?.id}`, {...data.reqBody})
        
        dispatch(
            setProfileState([
                {key: 'updateProfileData.loading', value: false},
                {key: 'updateProfileData.data', value: response.data.data}
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error.response?.data.message)
        dispatch(
            setProfileState([
                {key: 'updateProfileData.loading', value: false},
                {key: 'updateProfileData.error', value: true}
            ])
        )
    }
}

// ** Language Translate Api
export const languageTranslateApi = (data) => async (dispatch) => {
    try {
        dispatch(
            setProfileState([
                {key: 'loading', value: true},
                {key: 'error', value: false}
            ])
        )
        const response = await AxiosApi.put(`auth/change-language`, { language: data })
        toast.success(response.data.message)
        dispatch(
            setProfileState([
                {key: 'loading', value: false},
                {key: 'languageTranslateData.data', value: response.data}
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error.response.data.message)
        dispatch(
            setProfileState([
                {key: 'loading', value: false},
                {key: 'error', value: true}
            ])
        )
    }
}


const profileSlice = createSlice({
    name: 'profile',
    initialState: initialStates,
    reducers: {
        setProfileState: (state, { payload }) => {
            
            if (Array.isArray(payload)) {
                for (const obj of payload) {
                    

                    _.set(state, obj.key, obj.value)
                }
            } else {
                _.set(state, payload.key, payload.value)
            }
        }
    }
})

export const { setProfileState } = profileSlice.actions
const { reducer } = profileSlice
export default reducer