export default {
    addProfessionData:{
        loading: false,
        error: false,
        data: null
    },
    professionData: {
        loading: false,
        error: false,
        data: null
    },
    allProfessionData: {
        loading: false,
        error: false,
        data: null
    },
    allActiveProfessionData: {
        loading: false,
        error: false,
        data: null
    },
    allActiveAgeData: {
        loading: false,
        error: false,
        data: null
    },
    updateProfessionData: {
        loading: false,
        error: false,
        data: null
    },
    activeDeactiveProfessionData: {
        loading: false,
        error: false,
        data: null
    },
    professionDeleteData: {
        loading: false,
        error: false,
        data: null
    }
}