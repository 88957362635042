export default {
  allTechData: {
    loading: false,
    error: false,
    data: null
  },
  addTechData: {
    loading: false,
    error: false,
    data: null
  },
  updateTechData: {
    loading: false,
    error: false,
    data: null
  },
  deleteTechData: {
    loading: false,
    error: false,
    data: null
  }
};
