export default {
    addLocationData:{
        loading: false,
        error: false,
        data: null
    },
    locationData: {
        loading: false,
        error: false,
        data: null
    },
    allLocationData: {
        loading: false,
        error: false,
        data: null
    },
    allActiveLocationData: {
        loading: false,
        error: false,
        data: null
    },
    updateLocationData: {
        loading: false,
        error: false,
        data: null
    },
    activeDeactiveLocationData: {
        loading: false,
        error: false,
        data: null
    },
    locationDeleteData: {
        loading: false,
        error: false,
        data: null
    }
}