import axios from 'axios'
import { isUserLoggedIn, getAccessToken, userLogout } from './authFunctions'
import { useLocation, useHistory } from 'react-router-dom'

const baseURL = process.env.REACT_APP_API_ENDPOINT

export const AxiosApi = axios.create({ baseURL })

AxiosApi.defaults.headers['Access-Control-Allow-Origin'] = '*'

export function changeLangauge(lang) {
  AxiosApi.defaults.headers.common["Accept-Language"] = lang
}

if (isUserLoggedIn && getAccessToken()) {
  AxiosApi.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${getAccessToken()}`
  AxiosApi.defaults.headers.get["Content-Type"] = "application/json"
}
AxiosApi.defaults.headers.get["Content-Type"] = "application/json"

// AxiosApi.interceptors.request.use(
//   (request) => {
//     request.headers.common[
//       "Authorization"
//     ] = `Bearer ${getAccessToken()}`
//     return request
//   }, (error) => {
//     Promise.reject(error)
//   }
// )

AxiosApi.interceptors.response.use((response) => {
  return response
}, (error) => {
  const resposneStatus = error.response.status
  switch (resposneStatus) {
    case 401:
      userLogout()
      window.location.href = '/login'
      break

    default:
      throw error
      break
  }
})