
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { AxiosApi } from "@helper/axiosApi"
import initialStates from "./state"
import { data } from "jquery"

// ** create photographer
export const createPhotographer = (data) => async (dispatch) => {
    try {
        dispatch(
            setPhotographerState([
                { key:'addPhotographerData.loading', value:true },
                { key:'addPhotographerData.data', value:null },
                { key:'addPhotographerData.error', value:false }
            ])
        )
        const response = await AxiosApi.post('admin/photographer/create', data)
        toast.success(response.data.message)
        dispatch(
            setPhotographerState([
                { key:'addPhotographerData.loading', value:false },
                { key:'addPhotographerData.data', value:response.data.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setPhotographerState([
                { key:'addPhotographerData.loading', value:false },
                { key:'addPhotographerData.error', value:false }
            ])
        )
    }
}

// ** get all photographer
export const getAllPhotographer = (data) => async (dispatch) => {
    
    try {
        dispatch(
            setPhotographerState([
                { key:'allPhotographerData.loading', value:true },
                { key:'allPhotographerData.data', value:null },
                { key:'allPhotographerData.error', value:false }
            ])
        )

        const response = await AxiosApi.get('/admin/photographer/list', {
            params: data
        })
        dispatch(
            setPhotographerState([
                { key:'allPhotographerData.loading', value:false },
                { key:'allPhotographerData.data', value:response.data?.data }
            ])
        )
    } catch (error) {
        
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setPhotographerState([
                { key:'allPhotographerData.loading', value:false },
                { key:'allPhotographerData.error', value:true }
            ])
        )
    }
}

// ** get all photographer
export const getAllActivePhotographer = (data) => async (dispatch) => {
    try {
        dispatch(
            setPhotographerState([
                { key:'allActivePhotographerData.loading', value:true },
                { key:'allActivePhotographerData.data', value:null },
                { key:'allActivePhotographerData.error', value:false }
            ])
        )

        const response = await AxiosApi.get('/admin/photographer/active-list', {
            params: data
        })
        dispatch(
            setPhotographerState([
                { key:'allActivePhotographerData.loading', value:false },
                { key:'allActivePhotographerData.data', value:response.data?.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setPhotographerState([
                { key:'allActivePhotographerData.loading', value:false },
                { key:'allActivePhotographerData.error', value:true }
            ])
        )
    }
}

// ** get all photographer
export const updatePhotographer = (data) => async (dispatch) => {
    try {
        dispatch(
            setPhotographerState([
                { key: "updatePhotographerData.loading", value:true },
                { key: "updatePhotographerData.data", value:null },
                { key: "updatePhotographerData.error", value:false }
            ])
        )
        
        const response = await AxiosApi.put(`admin/photographer/update/${data.photographer_id}`, data.photographer_details)
        toast.success(response.data.message)
        
        dispatch(
            setPhotographerState([
                { key: "updatePhotographerData.loading", value:false },
                { key: "updatePhotographerData.data", value:response.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setPhotographerState([
                { key: "updatePhotographerData.loading", value:false },
                { key: "updatePhotographerData.error", value:true }
            ])
        )
    }
}

// ** get all photographer
export const deletePhotographer = (photographer_id) => async (dispatch) => {
    try {
        dispatch(
            setPhotographerState([
                { key:"photographerDeleteData.loading", value:true },
                { key:"photographerDeleteData.data", value:null },
                { key:"photographerDeleteData.error", value:true }
            ])
        )
        const response = await AxiosApi.delete(`/admin/photographer/delete/${photographer_id}`)
        dispatch(
            setPhotographerState([
                { key:"photographerDeleteData.loading", value:false },
                { key:"photographerDeleteData.data", value:response.data.data }
            ])
        )
        toast.success(response.data.message)
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setPhotographerState([
                { key:"photographerDeleteData.loading", value:false },
                { key:"photographerDeleteData.error", value:false }
            ])
        )
    }
}

//  ** Active Deactive Photographer
export const activeDeactivePhotographer = (data) => async (dispatch) => {
    const { id, status } = data
    try {
        dispatch(
            setPhotographerState([
                { key:"activeDeactivePhotographerData.loading", value:true },
                { key:"activeDeactivePhotographerData.data", value:null },
                { key:"activeDeactivePhotographerData.error", value:false }
            ])
        )
        const response = await AxiosApi.put(`/admin/photographer/manage-status/${id}`)
        toast.success(response?.data?.message)
        dispatch(
            setPhotographerState([
                { key:"activeDeactivePhotographerData.loading", value:false },
                { key:"activeDeactivePhotographerData.data", value:response.data?.data }
            ])
        )
    } catch (error) {
        if (!error) {
            throw error.response
        }
        toast.error(error?.response?.data?.message)
        dispatch(
            setPhotographerState([
                { key:"activeDeactivePhotographerData.loading", value:false },
                { key:"activeDeactivePhotographerData.error", value:true }
            ])
        )
        // return rejectWithValue(error.response.data)
    }
}

export const photographerSlice = createSlice({
    name: 'photographer',
    initialState: initialStates,
    reducers: {
        setPhotographerState: (state, { payload }) => {
            try {
                
                if (Array.isArray(payload)) {
                    for (const obj of payload) {
                        
                        _.set(state, obj.key, obj.value)
                    }
                } else {
                    _.set(state, payload.key, payload.value)
                }
            } catch (error) {
                
            }
        }
    }
})

export const { setPhotographerState } = photographerSlice.actions
const { reducer } = photographerSlice
export default reducer